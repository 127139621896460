import React from "react"

const questionsSectionData = [
    {
        Question: "Quem vai entregar meu presente?",
        Answer:
            "Ele será entregue por uma rede de fornecedores sob a responsabilidade do Gifthy, que conta com logística de mercado moderna, rápida e segura, para que o seu ato de presentear tenha a eficiência que você merece e precisa.",
    },
    {
        Question: "E se o meu presente não chegar a tempo?",
        Answer:
            "O seu tempo é muito valioso para o Gifthy. Sua estrutura conta com profissionais qualificados, focados na experiência de cada usuário. Salvo a existência de problemas logísticos excepcionais, se você comprar com a antecedência necessária, o seu presente chegará a tempo.",
    },
    {
        Question: "É o Gifthy quem vende os presentes?",
        Answer:
            "O Gifthy possui uma vitrine virtual que contempla diversas opções de presentes dentro de um sistema de marketplace. Dentro dele, foram eleitos fornecedores externos que possuem a mais alta credibilidade no mercado para proporcionar segurança para quem compra o presente e a satisfação para quem o recebe.",
    },
    {
        Question: "Como eu sei se o meu presente foi entregue?",
        Answer:
            "No Gifthy você está sempre por dentro de cada passo. E para se sentir mais seguro, receberá uma notificação informando o status de entrega do seu presente assim que ele for recebido.",
    },
    {
        Question: "Como faço o pagamento do presente",
        Answer:
            "O pagamento no Gifthy é feito através de cartão de crédito ou boleto bancário, pelos sistemas usuais de pagamento de compras no meio virtual.",
    },
]

const handleClick = index => {
    let element = document.getElementById(`faq-${index}`)
    let arrow = document.getElementById(`arrow-${index}`)

    arrow.style.transition = "transform 0.33s ease-out"

    if (arrow.style.transform === "rotate(180deg)") {
        arrow.style.transform = "rotate(0deg)"
    } else {
        arrow.style.transform = "rotate(180deg)"
    }

    console.log(!!element.style.maxHeight)

    if (element.style.maxHeight === "0px" || !!!element.style.maxHeight) {
        element.style.maxHeight = `${element.scrollHeight}px`
    } else {
        element.style.maxHeight = "0px"
    }
    element.classList.toggle("open")
}

const FAQItem = props => {
    const { Question, Answer, index } = props

    return (
        <button className="item" onClick={() => handleClick(index)}>
            <div className="question-box">
                <span className="title white-font">{Question}</span>
                <span className="arrow" id={`arrow-${index}`}>
                    <img
                        src={require("../../../components/assets/img/down-arrow.svg")}
                    />
                </span>
            </div>
            <div id={`faq-${index}`} className="answer-box">
                <div className="divider"></div>
                <p className="white-font">{Answer}</p>
            </div>
        </button>
    )
}

const FAQSection = () => {
    return (
        <section className="faq" id="faq">
            <div className="flexbox">
                <span className="title blue-font font-bold">
                    Principais Dúvidas
                </span>
                <span className="title white-font">
                    Veja abaixo as respostas para os principais questionamentos
                    dos usuários Gifthy
                </span>
                <div className="wrapper">
                    {questionsSectionData.map((item, index) => (
                        <FAQItem {...item} index={index} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default FAQSection
